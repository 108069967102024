import React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Row, Col } from 'antd';
import DOMPurify from 'isomorphic-dompurify';
import Img from 'gatsby-image';

import PublicLayout from '../../../../layout/public-layout';
import { InternalContainer } from '../../../../templates/basic-styles';
import PageHeader from '../../../../components/common/page-header';
import { useI18NContext } from '../../../../i18n';
import { useResponsive } from '../../../../hooks/use-responsive';
import theme from '../../../../theme';

const Header = styled.h2`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  padding: 15px 0;
  color: ${theme.colorsBlack};
`;

const SubHeader = styled.h6`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  color: ${theme.colorsBlack};
  margin-bottom: 40px;

  @media (max-width: ${theme.screenXsMax}) {
    padding-bottom: 15px;
  }
`;

const StyledCol = styled(Col)`
  padding: 0 20px;

  @media (min-width: ${theme.screenMd}) {
    padding: 0;
  }
`;

const Wrapper = styled(Row)`
  margin: 0 -16px;
`;

const Image = styled(Img)`
  width: 100%;
  border-radius: 5px;
  @media (max-width: ${theme.screenSmMax}) {
    margin-bottom: 30px;
  }
`;

const Text = styled.div`
  color: ${theme.colorsBlack};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  padding-left: 32px;
  & p {
    margin-bottom: 35px;
  }
`;

export default function HonoreeDetail({ location }: RouteComponentProps<any>) {
  const { allHonorees, honoreesSection } = useStaticQuery(graphql`
    query {
      allHonorees: allDirectusPastEventHonoreeDetail(filter: { status: { eq: "published" } }) {
        nodes {
          honoree_url {
            honoree_slug
          }
          honoree_name
          honoree_position
          translations {
            language
            honoree_desc
          }
          honoree_photo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            id
          }
        }
      }
      honoreesSection: allDirectusPastEventsHonoreesSection(filter: { status: { eq: "published" } }) {
        nodes {
          parent_event {
            slug
            translations {
              language
              name
            }
          }
          section_name
          section_url
        }
      }
    }
  `);

  const { language, translate, translateSlug } = useI18NContext();
  const { lg } = useResponsive();

  const urlPath = language === 'es' ? location?.pathname.replace('/es', '') : location?.pathname;

  const honoreesList =
    allHonorees.nodes && allHonorees.nodes.filter((x: any) => x.honoree_url.honoree_slug === urlPath);
  const honoree = honoreesList[0];

  const getParentEventUrl = (sectionUrl: string) => {
    const transformed = sectionUrl.split('/');
    const current = transformed[1];
    const result = `/${current}`;

    return result;
  };

  const honoreesSectionItem =
    urlPath && honoreesSection.nodes.filter((item: any) => item.parent_event.slug === getParentEventUrl(urlPath));

  const isSectionValid = honoreesSectionItem.length && honoreesSectionItem[0].parent_event !== undefined;

  const isMobile = !lg;

  return honoree ? (
    <PublicLayout seoTitle="Honoree Detail">
      <InternalContainer>
        <Row justify="space-between">
          <Col xs={24}>
            <PageHeader
              title=""
              breadcrumb={[
                <Link key="1" to={translateSlug('/')}>
                  Home
                </Link>,
                <Link key="2" to={translateSlug('/alumni-hall-of-fame')}>
                  Events: Signature Events
                </Link>,
                isSectionValid && (
                  <Link key="3" to={translateSlug(`${honoreesSectionItem[0].parent_event.slug}/honorees`)}>
                    {translate(honoreesSectionItem[0].parent_event).name}
                  </Link>
                ),
                honoree.honoree_name || '',
              ]}
              fullWidth
              isResponsive
              noShadow
            />
          </Col>
          {honoree && (
            <>
              <StyledCol xs={24}>
                <Header>{honoree.honoree_name}</Header>
                <SubHeader>
                  <div>{honoree.honoree_position}</div>
                </SubHeader>
                <Wrapper justify={isMobile ? 'center' : 'space-between'}>
                  <Col lg={6} md={12} xs={20}>
                    {honoree?.honoree_photo?.localFile?.childImageSharp?.fluid && (
                      <Image
                        fluid={honoree?.honoree_photo?.localFile?.childImageSharp?.fluid}
                        alt={honoree.honoree_name}
                        className="photo"
                      />
                    )}
                  </Col>
                  <Col lg={18} md={24}>
                    <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translate(honoree).honoree_desc) }} />
                  </Col>
                </Wrapper>
              </StyledCol>
            </>
          )}
        </Row>
      </InternalContainer>
    </PublicLayout>
  ) : (
    <div />
  );
}
